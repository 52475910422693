// this creates a sublist based on the selected tag

import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import { GatsbyImage } from "gatsby-plugin-image"

import { kebabCase } from 'lodash'
import {MainList,CatList} from '../components/utils.js'
import {Definition} from '../components/definitions.js'

// export const formattedImage = graphql`
//   fragment formattedImage on File {
//     childImageSharp {
//       fluid(maxWidth: 1080, maxHeight: 720) {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// `

const Categories = ({ pageContext, data }) => {
  const { category } = pageContext;
  const { edges, totalCount } = data.categories;
  const totalNow = data.projects.totalCount;
  const totalTalk= data.talks.totalCount;
  return (
    <Layout>
      <div className={"container"}>
        <MainList project={true} total={totalNow} totalTalk={totalTalk}/>
        <CatList category={true}/>
        <ul className={"tagList"}>
			<Link to={`/categories/${kebabCase(category)}/`}>
				<li key={category} className={"active"}>
					{category} ({totalCount})
				</li>
			</Link>
		</ul>
    <Definition type={"category"} lookup={category}/>
        {edges.map(({ node }) => (
          <section key={node.id}>
          <Link to={node.fields.slug}>
            <h3>
              {node.frontmatter.title}{", "}
              <span>
                {node.frontmatter.date}
              </span>
            </h3>
            <div className={"row"}>
                <div className={"col col40"}>
                    <GatsbyImage className="rnd" image={node.frontmatter.featuredImage.childImageSharp.gatsbyImageData} alt="image of category"/>
                </div>
                <div className={"col col60"}>
                   <div className={"row"}>
                    <div className={"lft pd"}>
                        <p>{node.excerpt}</p>
                    </div> 
                   </div>
                </div>
            </div>
            </Link>
          </section>
          ))}
          <MainList project={true} total={totalNow} totalTalk={totalTalk}/>
      </div>
    </Layout>
  );
};

export default Categories;

export const pageQuery = graphql`
  query($category: String) {
  	projects: allMarkdownRemark(
  		filter: {frontmatter: {template: {eq: "project"}}}
      limit: 2000
    ) {
      totalCount
    }
    talks: allMarkdownRemark(
      limit: 2000
      filter: {frontmatter: {template: {eq: "talk"}}}
    ) {
      totalCount
    }
    categories: allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt(pruneLength: 300)
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
               }
            }
          }
        }
      }
    }
  }
`;