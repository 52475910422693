import React from "react"
import "./definitions.scss"

import definitions from '../js/definitions.json';
import External from '../assets/external_link.svg';

const ExternalLink = ({link,site,title}) => {
	return link ?
		(<a className="externalLink" href={link}>
 				<div className="ext1">Visit {title ? title : site}</div>
 				<External/>
		</a>) : <div></div>
}

export const Definition = ({type,lookup}) => {
	return definitions[type][lookup] ? (
		<section>
		<div className={"brdrnd"}>
			<p>
				{definitions[type][lookup]['description']}
			</p>
			<ExternalLink link={definitions[type][lookup]['link']} site={lookup} title={definitions[type][lookup]['linkTitle']}/>
		</div>
		</section>
	) : <div className={"spacer s1"}></div>
}